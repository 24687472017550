import axios from 'axios'
import qs from 'qs'

const baseUrl = 'http://localhost:8001/api/token'

const login = async credentials => {
  const response = await axios.post(
    baseUrl,
    qs.stringify(credentials),
    {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  )
  return response.data
}

const exportLogin = { login }
export default exportLogin